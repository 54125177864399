@import '~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import '~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import '~carbon-components/scss/globals/scss/typography.scss';
@import '~carbon-components/scss/globals/scss/vars.scss';

$small: 320px;
$medium: 992px;

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@import './Landing.scss';
